<template>
  <q-page padding>
    <div class="row results">
      <results-card
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :index="index"
        :vendor="vendor"
        :rental-days="daysOnHire"
        :selected="selected === index"
        @selected="setSelected(item, index)"
      />
      <m-empty-state
        v-if="items && items.length === 0"
        icon="directions_car"
        style="margin:auto"
      >
        {{ $t('rental.no_cars_found') }}
      </m-empty-state>
    </div>
    <div class="cta">
      <m-cta-button
        :label="stash.selected ? $t('continue') : $t('select_an_option_to_continue')"
        :disabled="!stash.selected"
        @ctaClick="$router.push({name: 'ondemand-car-hire-summary', params: {token: stash.selected.value.token}})"
      />
    </div>
  </q-page>
</template>

<script>
import resultsCard from './results-card'
import store from 'store'
import loading from 'utils/loading'
import date from 'utils/date-time'
import { search } from 'api/rental'
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
import i18n from 'i18n'
import { MEmptyState, MCtaButton } from 'components/'

export default {
  name: 'OndemandCarHireResults',
  components: {
    resultsCard,
    MEmptyState,
    MCtaButton
  },
  data () {
    return {
      items: null,
      selected: null,
      vendor: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    daysOnHire () {
      const { dropoff, pickup, depart } = this.stash.parameters

      let pickupTime = pickup?.date || depart
      let dropoffTime = dropoff?.date || date.addToDate(pickupTime, { hours: 1 })

      let diff = date.getDateDiff(pickupTime, dropoffTime, 'days').values.days
      // Hire will always be for at least 1 day
      return diff >= 1 ? `${Math.ceil(diff)} days` : `1 day`
    }
  },
  mounted () {
    if (this.stash.selected) {
      this.selected = this.stash.selected.index
    }
    this.vendor = process.env.VUE_APP_RENTAL_VENDOR
  },
  methods: {
    setSelected (item, index) {
      this.selected = index
      this.$store.dispatch('ondemand/stash', {
        selected: {
          value: {
            ...item,
            index: index
          }
        }
      })
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { parameters } = store.getters['ondemand/stash']
    const { pickup, dropoff, traveller, requester, origin, destination, depart } = parameters

    // Get origin, destination, and dates depending on flow
    let pickupLoc = null
    let returnLoc = null
    let pickupTime = null
    let returnTime = null

    if (origin) {
      // We've come via the smartsearch flow, presumably
      pickupLoc = origin?.place_id || origin?.value
      returnLoc = destination?.place_id || destination?.value

      pickupTime = date.toCivilDateTime(depart)
      returnTime = date.toCivilDateTime(date.addToDate(pickupTime, { hours: 1 }))
    } else if (pickup) {
      // We've come via the ondemand flow
      pickupLoc = pickup.location.place_id || pickup.location.value
      returnLoc = dropoff?.location?.place_id || dropoff?.location?.value

      pickupTime = date.toCivilDateTime(pickup.date)
      returnTime = date.toCivilDateTime(dropoff.date)
    }

    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.rental'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const params = {
        origin: pickupLoc,
        destination: returnLoc || pickupLoc,
        pickup: pickupTime,
        return: returnTime,
        user: traveller.value,
        requester: requester.value
      }

      const { data } = await search(params)
      next(vm => {
        vm.items = data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.results
  max-width 768px
  margin 0
.resultCard
  margin 0px 0px 15px 0px
  @media (max-width 768px)
    margin-bottom 1px
hr
  width 100%
  margin-bottom 16px
.q-card
  transition: all .3s ease;
h4
  margin 35px 0 15px
</style>
